import Dashboard from "views/Dashboard/Dashboard.js";

import LoginPage from "views/Pages/LoginPage.js";

import NewDevice from "views/Device/newDevice.js";
import NewLocation from "views/Location/newLocation.js";
import EditLocation from "views/Location/editLocation";
import DetailsLocation from "views/Location/detailsLocation.js";
import Apps from "@material-ui/icons/Apps";
import BMS from "views/Apps/Apps";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/newdevice",
    name: "Crear Dispositivo",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: NewDevice,
    layout: "/admin",
  },
  {
    path: "/newlocation",
    name: "Crear Ubicación",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: NewLocation,
    layout: "/admin",
  },
  {
    path: "/apps",
    name: "Aplicaciones DMS",
    rtlName: "لوحة القيادة",
    icon: Apps,
    component: BMS,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/location/editar/:codigo",
    name: "Editar",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: EditLocation,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/location/:codigo",
    name: "Location",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DetailsLocation,
    layout: "/admin",
  },
  {
    invisible: true,
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
