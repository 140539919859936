import React, { useState, useEffect } from "react";

import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import AddCircle from "@material-ui/icons/AddCircle";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Datetime from "react-datetime";
import Snackbar from "components/Snackbar/Snackbar.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Divider from "@material-ui/core/Divider";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function NewDevice() {
  const initialState = {
    deviceId: "",
    serialNumber: "",
    deviceDescription: "",
    fechaCalibracion: "",
    fechaProxCalib: "",
    estado: "",
    ip: "",
  };
  const [device, setDevice] = React.useState(initialState);
  const [errors, setErrors] = React.useState(initialState);
  const [txtNotificacion, setTxtNotificacion] = useState(
    "Dispositivo almacenado correctamente"
  );
  const [tc, setTC] = React.useState(false);
  const [channel, setChannel] = React.useState([
    { id: 0, channelId: "", channelDescrip: "" },
  ]);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const verificar = () => {
    let errorMessage = {
      deviceId: "",
      serialNumber: "",
      deviceDescription: "",
      fechaCalibracion: "",
      fechaProxCalib: "",
      estado: "",
      ip: "",
    };

    let errors = 0;

    if (!device.deviceId) {
      errorMessage.deviceId = "Debe escribir el id del dispositivo";
      errors = errors + 1;
    }
    if (!device.serialNumber) {
      errorMessage.serialNumber = "Debe escribir el serial del dispositivo";
      errors = errors + 1;
    }
    if (!device.deviceDescription) {
      errorMessage.deviceDescription =
        "Debe escribir la descripción del dispositivo";
      errors = errors + 1;
    }
    if (!device.fechaCalibracion) {
      errorMessage.fechaCalibracion =
        "Debe seleccionar la fecha actual de calibracion del dispositivo";
      errors = errors + 1;
    }
    if (!device.fechaProxCalib) {
      errorMessage.fechaProxCalib =
        "Debe seleccionar la fecha procima de calibracion del dispositivo";
      errors = errors + 1;
    }
    if (!device.estado) {
      errorMessage.estado = "Debe seleccionar el estado del dispositivo";
      errors = errors + 1;
    }
    // if (!device.channel.length < 1) {
    //   errorMessage.channelId = "Debe escribir el id del canal del dispositivo";
    //   errorMessage.channelDescrip =
    //     "Debe escribir la descripción del canal del dispositivo";
    //   errors = errors + 1;
    // }
    if (!device.ip) {
      errorMessage.ip = "Debe escribir el ip del dispositivo";
      errors = errors + 1;
    }

    setErrors(errorMessage);
    return errors;
  };

  const handleDeviceSubmit = async (event) => {
    event.preventDefault();
    let flag = verificar();
    if (flag === 0) {
      const deviceToSave = {
        deviceId: device.deviceId,
        serialNumber: device.serialNumber,
        deviceDescription: device.deviceDescription,
        fechaCalibracion: device.fechaCalibracion,
        fechaProxCalib: device.fechaProxCalib,
        estado: device.estado,
        channel: channel,
        ip: device.ip,
      };
      await axios
        // .post("/api/vaisala/newDevice", deviceToSave)
        .post("https://epstool.com/api/vaisala/newDevice", deviceToSave)
        .then((res) => {
          setDevice(initialState);
          setErrors(initialState);
          showNotification();
        })
        .catch((error) => {
          setErrors(console.log(error));
        });
    }
  };

  const classes = useStyles();

  const handleChannelInput = async (event, id, inpt) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (inpt) {
      case 1:
        channel[id].channelId = txt;
        break;
      case 2:
        channel[id].channelDescrip = txt;
        break;
      default:
        break;
    }
    setChannel([...channel]);
  };

  const handleAddChannel = async (event) => {
    event.preventDefault();
    setChannel([
      ...channel,
      {
        id: channel.length,
        channelId: "",
        channelDescrip: "",
      },
    ]);
  };

  let inputChannel;
  inputChannel = channel.map((inputs) => (
    <GridContainer key={inputs.id.toString()}>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelHorizontal}>Channel Id</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          multiline
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleChannelInput(e, inputs.id, 1),
            type: "text",
            value: channel[inputs.id].channelId,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelHorizontal}>
          Channel Description
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          multiline
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleChannelInput(e, inputs.id, 2),
            type: "text",
            value: channel[inputs.id].channelDescrip,
          }}
        />
      </GridItem>
      <Divider />
    </GridContainer>
  ));

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Crear Dispositivo</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Device Id*
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="deviceId"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({ ...device, deviceId: e.target.value });
                    },
                    type: "text",
                    value: device.deviceId,
                  }}
                />
                <Danger>
                  {errors.deviceId === undefined ? null : (
                    <div className="invalid-feedback">{errors.deviceId}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Serial Number
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="serial"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({ ...device, serialNumber: e.target.value });
                    },
                    type: "text",
                    value: device.serialNumber,
                  }}
                />
                <Danger>
                  {errors.serialNumber === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.serialNumber}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Device Description
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({
                        ...device,
                        deviceDescription: e.target.value,
                      });
                    },
                    type: "text",
                    value: device.deviceDescription,
                  }}
                />
                <Danger>
                  {errors.deviceDescription === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.deviceDescription}
                    </div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Device IP
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="ip"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({
                        ...device,
                        ip: e.target.value,
                      });
                    },
                    type: "text",
                    value: device.ip,
                  }}
                />
                <Danger>
                  {errors.ip === undefined ? null : (
                    <div className="invalid-feedback">{errors.ip}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            {inputChannel}
            <Divider />
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <Button
                  fullWidth
                  simple
                  color="success"
                  onClick={handleAddChannel}
                >
                  {/* <Button fullWidth color="success"> */}
                  <AddCircle className={classes.icons} /> Añadir Channel
                </Button>
              </GridItem>
            </GridContainer>
            <Divider />
            {/* <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Channel Id
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="channel"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({
                        ...device,
                        channelId: e.target.value,
                      });
                    },
                    type: "text",
                    value: device.channelId,
                  }}
                />
                <Danger>
                  {errors.channelId === undefined ? null : (
                    <div className="invalid-feedback">{errors.channelId}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Channel Description
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="channelDescr"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({
                        ...device,
                        channelDescrip: e.target.value,
                      });
                    },
                    type: "text",
                    value: device.channelDescrip,
                  }}
                />
                <Danger>
                  {errors.channelDescrip === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.channelDescrip}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer> */}
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Estado
                </FormLabel>
              </GridItem>
              <GridItem xs={12} xs={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Estado*
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={device.estado}
                    onChange={(e) =>
                      setDevice({ ...device, estado: e.target.value })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Estado
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="ONLINE"
                    >
                      ONLINE
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="EN CALIBRACION"
                    >
                      EN CALIBRACION
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="OFFLINE"
                    >
                      OFFLINE
                    </MenuItem>
                  </Select>
                  <Danger>
                    {errors.estado === undefined ? null : (
                      <div className="invalid-feedback">{errors.estado}</div>
                    )}
                  </Danger>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Fechas de Calibración:
                </FormLabel>
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem md={2}></GridItem>
              <GridItem xs={12} md={4}>
                <FormControl fullWidth>
                  <Datetime
                    onChange={(e) =>
                      setDevice({ ...device, fechaCalibracion: e.unix() })
                    }
                    timeFormat={false}
                    inputProps={{ placeholder: "Actual Calibración" }}
                  />
                </FormControl>
                <Danger>
                  {errors.fechaCalibracion === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.fechaCalibracion}
                    </div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} md={4}>
                <FormControl fullWidth>
                  <Datetime
                    onChange={(e) =>
                      setDevice({ ...device, fechaProxCalib: e.unix() })
                    }
                    timeFormat={false}
                    inputProps={{ placeholder: "Próxima Calibración" }}
                  />
                </FormControl>
                <Danger>
                  {errors.fechaProxCalib === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.fechaProxCalib}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
          </form>
          <br></br>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Button fullWidth color="success" onClick={handleDeviceSubmit}>
                Crear Dispositivo
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </GridContainer>
  );
}
