import React, { useState, useEffect } from "react";

import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";

// @material-ui/icons
import AddCircle from "@material-ui/icons/AddCircle";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Datetime from "react-datetime";
import Snackbar from "components/Snackbar/Snackbar.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Divider from "@material-ui/core/Divider";
//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Success from "components/Typography/Success";
import errorPageStyles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function EditLocation(props) {
  const initialStateLocation = {
    codigo: "",
    nombre: "",
    ubicacion: "",
    device: "",
    deviceId: "",
    channelId: "",
  };
  const initialState = {
    deviceId: "",
    serialNumber: "",
    deviceDescription: "",
    fechaCalibracion: "",
    fechaProxCalib: "",
    estado: "",
    ip: "",
  };
  const [location, setLocation] = React.useState(initialStateLocation);
  const [device, setDevice] = React.useState(initialState);
  const [channel, setChannel] = React.useState([
    { id: 0, channelId: "", channelDescrip: "" },
  ]);
  const [errors, setErrors] = React.useState(initialStateLocation);
  const [success, setSuccess] = React.useState(initialStateLocation);
  const [txtNotificacion, setTxtNotificacion] = useState(
    "Ubicación almacenado correctamente"
  );
  const [alertModal, setAlertModal] = React.useState(false);

  const [tc, setTC] = React.useState(false);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const fetchData = async () => {
    const result = await axios.get(
      `https://epstool.com/api/vaisala/location/${props.match.params.codigo}`
    //   `/api/vaisala/location/${props.match.params.codigo}`
    );
    console.log(result.data);

    setLocation(result.data);
    setDevice(result.data.devices[0]);
    // if (result.data.devices[0].channel.length > 1) {
    //   result.data.devices[0].channel.forEach((element) => {
    //     if (element.codeLocation) {
    //       if (element.codeLocation === result.data.codigo.toString()) {
    //         setChannel(element);
    //       }
    //     }
    //   });
    // } else {
    //   setChannel(result.data.devices[0].channel[0]);
    // }
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const verificar = () => {
    let errorMessage = {
      codigo: "",
      nombre: "",
      ubicacion: "",
      deviceId: "",
      device: "",
    };

    let errors = 0;

    if (!location.deviceId) {
      errorMessage.device = "Debe verificar el id del dispositivo a vincular";
      errors = errors + 1;
    }
    if (!location.codigo) {
      errorMessage.codigo = "Debe escribir el codigo de la ubicacion";
      errors = errors + 1;
    }
    if (!location.nombre) {
      errorMessage.nombre = "Debe escribir el nombre de la ubicacion";
      errors = errors + 1;
    }
    if (!location.ubicacion) {
      errorMessage.ubicacion = "Debe seleccionar la ubicacion";
      errors = errors + 1;
    }

    setErrors(errorMessage);
    return errors;
  };

  const handleLocationSubmit = async (event) => {
    console.log(location);

    event.preventDefault();
    let flag = verificar();
    if (flag === 0) {
      const locationToSave = {
        codigo: location.codigo,
        nombre: location.nombre,
        ubicacion: location.ubicacion,
        devices: location.deviceId,
        channelId: location.channelId,
      };
      await axios
        // .post("/api/vaisala/newLocation", locationToSave)
        .post("https://epstool.com/api/vaisala/newLocation", locationToSave)
        .then((res) => {
          setLocation(initialStateLocation);
          setErrors(initialStateLocation);
          setSuccess(initialStateLocation);
          showNotification();
        })
        .catch((error) => {
          setErrors(console.log(error));
        });
    }
  };

  const classes = useStyles();

  const handleChannelInput = async (event, id, inpt) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (inpt) {
      case 1:
        channel[id].channelId = txt;
        break;
      case 2:
        channel[id].channelDescrip = txt;
        break;
      default:
        break;
    }
    setChannel([...channel]);
  };

  const handleAddChannel = async (event) => {
    event.preventDefault();
    setChannel([
      ...channel,
      {
        id: channel.length,
        channelId: "",
        channelDescrip: "",
      },
    ]);
  };

  let inputChannel;
  inputChannel = channel.map((inputs) => (
    <GridContainer key={inputs.id.toString()}>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelHorizontal}>Channel Id</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          multiline
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleChannelInput(e, inputs.id, 1),
            type: "text",
            value: channel[inputs.id].channelId,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelHorizontal}>
          Channel Description
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          multiline
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleChannelInput(e, inputs.id, 2),
            type: "text",
            value: channel[inputs.id].channelDescrip,
          }}
        />
      </GridItem>
      <Divider />
    </GridContainer>
  ));

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Editar Información</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <h4>Información de la locación</h4>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Nombre
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  id="nombre"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setLocation({
                        ...location,
                        nombre: e.target.value,
                      });
                    },
                    type: "text",
                    value: location.nombre,
                  }}
                />
                <Danger>
                  {errors.nombre === undefined ? null : (
                    <div className="invalid-feedback">{errors.nombre}</div>
                  )}
                </Danger>
              </GridItem>

              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Ubicacion
                </FormLabel>
              </GridItem>
              <GridItem xs={12} xs={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Ubicación*
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={location.ubicacion}
                    onChange={(e) =>
                      setLocation({ ...location, ubicacion: e.target.value })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Ubicación
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 1"
                    >
                      CER 1
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 2"
                    >
                      CER 2
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 3"
                    >
                      CER 3
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 4"
                    >
                      CER 4
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 5"
                    >
                      CER 5
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Zona 10"
                    >
                      Zona 10
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="SA"
                    >
                      SA
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Areas Producto Terminado"
                    >
                      Areas Producto Terminado
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Microbiology Lab"
                    >
                      Microbiology Lab
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Calibration Lab"
                    >
                      Calibration Lab
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Refrigerators"
                    >
                      Refrigerators
                    </MenuItem>
                  </Select>
                  <Danger>
                    {errors.ubicacion === undefined ? null : (
                      <div className="invalid-feedback">{errors.ubicacion}</div>
                    )}
                  </Danger>
                </FormControl>
              </GridItem>
            </GridContainer>
            <h4>Información del dispositivo</h4>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Device Id*
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="deviceId"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({ ...device, deviceId: e.target.value });
                    },
                    type: "text",
                    value: device.deviceId,
                  }}
                />
                <Danger>
                  {errors.deviceId === undefined ? null : (
                    <div className="invalid-feedback">{errors.deviceId}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Serial Number
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="serial"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({ ...device, serialNumber: e.target.value });
                    },
                    type: "text",
                    value: device.serialNumber,
                  }}
                />
                <Danger>
                  {errors.serialNumber === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.serialNumber}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Device Description
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({
                        ...device,
                        deviceDescription: e.target.value,
                      });
                    },
                    type: "text",
                    value: device.deviceDescription,
                  }}
                />
                <Danger>
                  {errors.deviceDescription === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.deviceDescription}
                    </div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Device IP
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="ip"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setDevice({
                        ...device,
                        ip: e.target.value,
                      });
                    },
                    type: "text",
                    value: device.ip,
                  }}
                />
                <Danger>
                  {errors.ip === undefined ? null : (
                    <div className="invalid-feedback">{errors.ip}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            {inputChannel}
            <Divider />
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <Button
                  fullWidth
                  simple
                  color="success"
                  onClick={handleAddChannel}
                >
                  {/* <Button fullWidth color="success"> */}
                  <AddCircle className={classes.icons} /> Añadir Channel
                </Button>
              </GridItem>
            </GridContainer>
            <Divider />

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Estado
                </FormLabel>
              </GridItem>
              <GridItem xs={12} xs={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Estado*
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={device.estado}
                    onChange={(e) =>
                      setDevice({ ...device, estado: e.target.value })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Estado
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="ONLINE"
                    >
                      ONLINE
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="EN CALIBRACION"
                    >
                      EN CALIBRACION
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="OFFLINE"
                    >
                      OFFLINE
                    </MenuItem>
                  </Select>
                  <Danger>
                    {errors.estado === undefined ? null : (
                      <div className="invalid-feedback">{errors.estado}</div>
                    )}
                  </Danger>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel className={classes.labelHorizontal}>
                  Fechas de Calibración:
                </FormLabel>
              </GridItem>
            </GridContainer>
            <br></br>
            <GridContainer>
              <GridItem md={2}></GridItem>
              <GridItem xs={12} md={4}>
                <FormControl fullWidth>
                  <Datetime
                    onChange={(e) =>
                      setDevice({ ...device, fechaCalibracion: e.unix() })
                    }
                    timeFormat={false}
                    inputProps={{ placeholder: "Actual Calibración" }}
                  />
                </FormControl>
                <Danger>
                  {errors.fechaCalibracion === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.fechaCalibracion}
                    </div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} md={4}>
                <FormControl fullWidth>
                  <Datetime
                    onChange={(e) =>
                      setDevice({ ...device, fechaProxCalib: e.unix() })
                    }
                    timeFormat={false}
                    inputProps={{ placeholder: "Próxima Calibración" }}
                  />
                </FormControl>
                <Danger>
                  {errors.fechaProxCalib === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors.fechaProxCalib}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <br></br>
          </form>
          <br></br>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Button
                fullWidth
                color="success"
                onClick={() => setAlertModal(true)}
              >
                Editar Información
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Button
                fullWidth
                color="danger"
                onClick={() =>
                  (window.location.href = `../${props.match.params.codigo}`)
                }
              >
                Cancelar
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={alertModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAlertModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            La función de edición se encuentra en revisión, para mas detalles
            contactar al correo soporte@fusiontech.pro{" "}
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setAlertModal(false)} color="success" block>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
