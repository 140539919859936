import React, { useState, useEffect } from "react";

import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import Datetime from "react-datetime";
import Snackbar from "components/Snackbar/Snackbar.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Success from "components/Typography/Success";
import errorPageStyles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles";

const useStyles = makeStyles(styles);

export default function NewLocation() {
  const initialState = {
    codigo: "",
    nombre: "",
    ubicacion: "",
    device: "",
    deviceId: "",
    channelId: "",
  };
  const [location, setLocation] = React.useState(initialState);
  const [errors, setErrors] = React.useState(initialState);
  const [success, setSuccess] = React.useState(initialState);
  const [txtNotificacion, setTxtNotificacion] = useState(
    "Ubicación almacenado correctamente"
  );
  const [tc, setTC] = React.useState(false);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const verificarDeviceId = async () => {
    if (!location.device) {
      setErrors({ ...errors, device: "Ingrese un id valido" });
    } else {
      await axios
        // .get(`/api/vaisala/device/${location.device}`)
        .get(`https://epstool.com/api/vaisala/device/${location.device}`)
        .then((res) => {
          if (res.data === null) {
            setErrors({ ...errors, device: "El Id ingresado no corresponde" });
            setSuccess({ ...success, device: "" });
          } else {
            setErrors({ ...errors, device: "" });
            setSuccess({ ...success, device: "Id verificado correctamente" });
            setLocation({ ...location, deviceId: res.data._id });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    }
  };
  const verificar = () => {
    let errorMessage = {
      codigo: "",
      nombre: "",
      ubicacion: "",
      deviceId: "",
      device: "",
    };

    let errors = 0;

    if (!location.deviceId) {
      errorMessage.device = "Debe verificar el id del dispositivo a vincular";
      errors = errors + 1;
    }
    if (!location.codigo) {
      errorMessage.codigo = "Debe escribir el codigo de la ubicacion";
      errors = errors + 1;
    }
    if (!location.nombre) {
      errorMessage.nombre = "Debe escribir el nombre de la ubicacion";
      errors = errors + 1;
    }
    if (!location.ubicacion) {
      errorMessage.ubicacion = "Debe seleccionar la ubicacion";
      errors = errors + 1;
    }

    setErrors(errorMessage);
    return errors;
  };

  const handleLocationSubmit = async (event) => {
    console.log(location);

    event.preventDefault();
    let flag = verificar();
    if (flag === 0) {
      const locationToSave = {
        codigo: location.codigo,
        nombre: location.nombre,
        ubicacion: location.ubicacion,
        devices: location.deviceId,
        channelId: location.channelId,
      };
      await axios
        // .post("/api/vaisala/newLocation", locationToSave)
        .post("https://epstool.com/api/vaisala/newLocation", locationToSave)
        .then((res) => {
          setLocation(initialState);
          setErrors(initialState);
          setSuccess(initialState);
          showNotification();
        })
        .catch((error) => {
          setErrors(console.log(error));
        });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Crear Ubicación</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Código
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <CustomInput
                  id="codigo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setLocation({ ...location, codigo: e.target.value });
                    },
                    type: "text",
                    value: location.codigo,
                  }}
                />
                <Danger>
                  {errors.codigo === undefined ? null : (
                    <div className="invalid-feedback">{errors.codigo}</div>
                  )}
                </Danger>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Estado
                </FormLabel>
              </GridItem>
              <GridItem xs={12} xs={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Ubicación*
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={location.ubicacion}
                    onChange={(e) =>
                      setLocation({ ...location, ubicacion: e.target.value })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Ubicación
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 1"
                    >
                      CER 1
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 2"
                    >
                      CER 2
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 3"
                    >
                      CER 3
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 4"
                    >
                      CER 4
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="CER 5"
                    >
                      CER 5
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Zona 10"
                    >
                      Zona 10
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="SA"
                    >
                      SA
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Areas Producto Terminado"
                    >
                      Areas Producto Terminado
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Microbiology Lab"
                    >
                      Microbiology Lab
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Calibration Lab"
                    >
                      Calibration Lab
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Refrigerators"
                    >
                      Refrigerators
                    </MenuItem>
                  </Select>
                  <Danger>
                    {errors.ubicacion === undefined ? null : (
                      <div className="invalid-feedback">{errors.ubicacion}</div>
                    )}
                  </Danger>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Nombre
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  id="nombre"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setLocation({
                        ...location,
                        nombre: e.target.value,
                      });
                    },
                    type: "text",
                    value: location.nombre,
                  }}
                />
                <Danger>
                  {errors.nombre === undefined ? null : (
                    <div className="invalid-feedback">{errors.nombre}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  ID Dispositivo:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  id="device"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setLocation({
                        ...location,
                        device: e.target.value,
                      });
                    },
                    type: "text",
                    value: location.device,
                  }}
                />
                <Danger>
                  {errors.device === undefined ? null : (
                    <div className="invalid-feedback">{errors.device}</div>
                  )}
                </Danger>
                <Success>
                  {success.device === undefined ? null : (
                    <div className="invalid-feedback">{success.device}</div>
                  )}
                </Success>
              </GridItem>
              <GridItem xs={12} md={4}>
                <Button
                  fullWidth
                  size="sm"
                  color="success"
                  onClick={verificarDeviceId}
                >
                  Verificar
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Channel Id:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  id="channel"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => {
                      setLocation({
                        ...location,
                        channelId: e.target.value,
                      });
                    },
                    type: "text",
                    value: location.channelId,
                  }}
                />
              </GridItem>
            </GridContainer>
            <br></br>
          </form>
          <br></br>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Button fullWidth color="success" onClick={handleLocationSubmit}>
                Crear Ubicación
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </GridContainer>
  );
}
