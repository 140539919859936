import React, { useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Forward from "@material-ui/icons/Forward";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  const [locations, setLocations] = React.useState([]);

  // CARGA DE LA RONDA
  const fetchDataLocations = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      `https://epstool.com/api/vaisala/locations`
      // `/api/vaisala/locations`
    );

    setLocations(result.data);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchDataLocations();
  }, []);

  let tbLocations;
  Object.keys(locations).length >= 1
    ? (tbLocations = locations.map((location) => {
        return {
          nombre: location.nombre,
          ubicacion: location.ubicacion,
          device: location.devices[0].deviceId,
          ip: location.devices[0].ip,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  props.history.push(`location/${location.codigo}`);
                }}
                color="success"
                className="edit"
              >
                <Forward />
              </Button>{" "}
            </div>
          ),
        };
      }))
    : (tbLocations = []);

  return (
    <GridContainer>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <ReactTable
                columns={[
                  {
                    Header: "Nombre",
                    accessor: "nombre",
                  },
                  {
                    Header: "Ubicacion",
                    accessor: "ubicacion",
                  },
                  {
                    Header: "Device Id",
                    accessor: "device",
                  },
                  {
                    Header: "IP",
                    accessor: "ip",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={tbLocations}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
