import React, { useEffect } from "react";

import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import jwt_decode from "jwt-decode";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import CustomInput from "components/CustomInput/CustomInput.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Forward from "@material-ui/icons/Forward";
import Danger from "components/Typography/Danger";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardFooter from "components/Card/CardFooter";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DetailsLocation(props) {
  const classes = useStyles();
  const [principalLocation, setPrincipalLocation] = React.useState();
  const [device, setDevice] = React.useState();
  const [channel, setChannel] = React.useState();
  const [extraLocations, setExtraLocations] = React.useState([]);
  const [notaModal, setNotaModal] = React.useState(false);
  const [cambiarModal, setCambiarModal] = React.useState(false);
  const [textNote, setTextNote] = React.useState("");
  const [txtDeviceId, setTxtDeviceId] = React.useState("");
  const [txtMotivo, setTxtMotivo] = React.useState("");
  const [tc, setTC] = React.useState(false);
  const [txtNotificacion, setTxtNotificacion] = React.useState("");
  const [notas, setNotas] = React.useState([]);
  const [bitacoras, setBitacoras] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [eliminarModal, setEliminarModal] = React.useState(false);

  const fetchData = async () => {
    const result = await axios.get(
      `https://epstool.com/api/vaisala/location/${props.match.params.codigo}`
      // `/api/vaisala/location/${props.match.params.codigo}`
    );
    setPrincipalLocation(result.data);
    setDevice(result.data.devices[0]);
    setNotas(result.data.notas);
    setBitacoras(result.data.bitacora);
    // console.log(result.data.devices[0].channel);
    let extrasLocat = [];
    if (result.data.devices[0].channel.length > 1) {
      result.data.devices[0].channel.forEach((element) => {
        if (element.codeLocation) {
          if (element.codeLocation === result.data.codigo.toString()) {
            setChannel(element);
          } else {
            extrasLocat.push(element);
          }
        }
      });
      setExtraLocations(extrasLocat);
    } else {
      setChannel(result.data.devices[0].channel[0]);
    }
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  // GUARDAR NOTA
  const handleNotaSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    const notaToSave = {
      nota: textNote,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      .post(
        `https://epstool.com/api/vaisala/nota/${principalLocation._id}`,
        notaToSave
      )
      // .post(`/api/vaisala/nota/${principalLocation._id}`, notaToSave)
      .then((res) => {
        setNotas(res.data.location.notas);
        setTxtNotificacion("Nota guardada con éxito");
        setTextNote("");
        setNotaModal(false);
        showNotification();
      })
      .catch((error) => {
        console.log(error);
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  const verificar = () => {
    let errorMessage = {
      codigo: "",
      motivo: "",
    };

    let errors = 0;

    if (!txtDeviceId) {
      errorMessage.codigo = "Debe escribir el id del nuevo dispositivo";
      errors = errors + 1;
    }
    if (!txtMotivo) {
      errorMessage.motivo = "Debe escribir el motivo del cambio";
      errors = errors + 1;
    }
    setErrors(errorMessage);
    return errors;
  };

  // Eliminar polos
  const handleDelete = async () => {
    await axios
      .delete(
        `https://epstool.com/api/vaisala/eliminarLocation/${principalLocation._id}`
      )
      // .delete(`/api/vaisala/eliminarLocation/${principalLocation._id}`)
      .then((res) => {
        props.history.push(`../admin`);
      })
      .catch((error) => {
        console.log(error);

        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  // Realizar cambio de dispositivo
  const handleCambiarSubmit = async (event) => {
    event.preventDefault();
    let flag = verificar();
    if (flag === 0) {
      const decoded = jwt_decode(localStorage.jwtToken);
      const cambiarToSave = {
        oldDevice: device.deviceId,
        motivo: txtMotivo,
        userName: decoded.name,
        userCompany: decoded.company,
      };

      await axios
        .post(
          `https://epstool.com/api/vaisala/location/cambiar/${principalLocation.codigo}/${txtDeviceId}`,
          cambiarToSave
        )
        // .post(
        //   `/api/vaisala/location/cambiar/${principalLocation.codigo}/${txtDeviceId}`,
        //   cambiarToSave
        // )
        .then((res) => {
          fetchData();
          setTxtNotificacion("Cambio realizado con éxito");
          setTxtDeviceId("");
          setTxtMotivo("");
          setErrors("");
          setCambiarModal(false);
          showNotification();
        })
        .catch((error) => {
          setErrors(error.response.data);
        });
    }
  };

  const redirect = (codigo) => {
    props.history.push(`./${codigo}`);
    window.location.reload();
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {principalLocation ? principalLocation.nombre : ""}
              </h4>
            </CardHeader>
            <CardBody>
              <br></br>
              <GridContainer>
                <GridItem xs={12}>
                  <strong>Dispositivo Registrado:</strong>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>{device ? `Device Id: ${device.deviceId}` : null}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {device
                      ? `Device Description: ${device.deviceDescription}`
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>{device ? `Estado: ${device.estado}` : null}</p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>{device ? `IP: ${device.ip}` : null}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>{channel ? `Channel ID: ${channel.channelId}` : null}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {channel
                      ? `Channel Description: ${channel.channelDescrip}`
                      : null}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>
                    {device ? `Serial number: ${device.serialNumber}` : null}
                  </p>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12}>
                  <strong>Fechas de calibración:</strong>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <p>
                    {device
                      ? `Ultima calibración: ${new Date(
                          device.fechaCalibracion * 1000
                        ).toLocaleDateString()}`
                      : null}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <p>
                    {device
                      ? `Próxima calibración: ${new Date(
                          device.fechaProxCalib * 1000
                        ).toLocaleDateString()}`
                      : null}
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <Button
                    size="sm"
                    fullWidth
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setCambiarModal(true);
                    }}
                  >
                    Cambiar Dispositivo
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Button
                    size="sm"
                    fullWidth
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() =>
                      (window.location.href = `./editar/${principalLocation.codigo}`)
                    }
                  >
                    Editar
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Button
                    size="sm"
                    fullWidth
                    className={classes.cardButtonTitle}
                    color="danger"
                    onClick={() => {
                      setEliminarModal(true);
                    }}
                  >
                    Elimiar
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      {/* Otros locations */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridItem xs={12} sm={12} md={9}>
                <h4 className={classes.cardTitle}>
                  Ubicaciones del dispositivo
                </h4>
              </GridItem>
            </CardHeader>
            <CardBody>
              {device ? (
                <Table
                  tableHead={["Nombre", "Channel ID", "Ir a"]}
                  tableData={extraLocations.map((location) => [
                    location.nameLocation,
                    location.channelId,
                    <Button
                      justIcon
                      color="success"
                      size="sm"
                      onClick={() => {
                        redirect(location.codeLocation);
                      }}
                    >
                      <Forward />
                    </Button>,
                  ])}
                  customCellClasses={[
                    classes.center,
                    classes.center,
                    classes.right,
                  ]}
                  customClassesForCells={[0, 1]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.center,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[0, 1]}
                />
              ) : null}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* Otros Bitacora */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridItem xs={12} sm={12} md={9}>
                <h4 className={classes.cardTitle}>Bitacora</h4>
              </GridItem>
            </CardHeader>
            <CardBody>
              {bitacoras.length === 0
                ? "No se registran bitacoras"
                : bitacoras.map((bitacora) => (
                    <div key={bitacora._id}>
                      <strong>
                        Nota del dia{" "}
                        {new Date(bitacora.fecha * 1000).toLocaleString()}:
                      </strong>
                      <p>{bitacora.comentario}.</p>
                      <p>
                        Realizado por {bitacora.userName} -{" "}
                        {bitacora.userCompany}
                      </p>
                    </div>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* Otros Notas */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                  <h4 className={classes.cardTitle}>Notas</h4>
                </GridItem>
                <GridItem>
                  <Button
                    size="sm"
                    className={classes.cardButtonTitle}
                    color="success"
                    onClick={() => {
                      setNotaModal(true);
                    }}
                  >
                    Crear nota
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {notas.length === 0
                ? "No se registran notas"
                : notas.map((nota) => (
                    <div key={nota._id}>
                      <strong>
                        Nota del dia{" "}
                        {new Date(nota.fecha * 1000).toLocaleString()}:
                      </strong>
                      <p>{nota.comentario}.</p>
                      <p>
                        Realizado por {nota.userName} - {nota.userCompany}
                      </p>
                    </div>
                  ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={notaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Crear Nota</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir comentario"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextNote(event.target.value);
              },
              type: "text",
              value: textNote,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleNotaSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setNotaModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL CAMBIAR */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={cambiarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setCambiarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setCambiarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Cambio de dispositivo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>
            Porfavor ingrese el id del nuevo dispositivo a anexar en esta
            locación:
          </p>
          <CustomInput
            multiline
            labelText="Device Id"
            id="txtDeviceId"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTxtDeviceId(event.target.value);
              },
              type: "number",
              value: txtDeviceId,
            }}
          />
          <Danger>
            {errors.codigo && (
              <div className="invalid-feedback">{errors.codigo}</div>
            )}
          </Danger>
          <br />
          <p>Porfavor detalle la razón del cambio:</p>
          <CustomInput
            multiline
            labelText="Motivo"
            id="txtMotivo"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTxtMotivo(event.target.value);
              },
              type: "text",
              value: txtMotivo,
            }}
          />
          <Danger>
            {errors.motivo && (
              <div className="invalid-feedback">{errors.motivo}</div>
            )}
          </Danger>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleCambiarSubmit} color="success">
            Cambiar
          </Button>
          <Button
            onClick={() => (window.location.href = "../newdevice")}
            color="success"
          >
            Crear Device
          </Button>
          <Button onClick={() => setCambiarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL Eliminar */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={eliminarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEliminarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEliminarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Alerta</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <h3>
            Está seguro de eliminar esta locación? Esta acción no puede ser
            corregida.
          </h3>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleDelete} color="success">
            Eliminar
          </Button>
          <Button onClick={() => setEliminarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
